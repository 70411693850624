import * as React from 'react';

type Props = {
  className?: string;
};

const PinIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    overflow="visible"
    width="64px"
    height="64px"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M32,5.125 C36.94792,5.125 41.2447967,7.11067667 44.89063,11.08203 C48.5364633,15.0533833 50.35938,19.6432267 50.35938,24.85156 C50.35938,32.66406 44.4349,43.9921833 32.58594,58.83593 C32.4557267,58.9661367 32.2604133,59.03124 32,59.03124 C31.7395867,59.03124 31.5442733,58.9661367 31.41406,58.83593 C19.5651067,44.12239 13.64062,32.7942667 13.64062,24.85156 C13.64062,19.6432267 15.46354,15.0859333 19.10938,11.17968 C22.7552067,7.14322667 27.05208,5.125 32,5.125 Z M32,2 C26.1406267,2 21.0950533,4.31119667 16.86328,8.93359 C12.6315067,13.5559833 10.51562,18.8619733 10.51562,24.85156 C10.51562,34.22656 16.76562,46.14062 29.26562,60.59374 C29.9166667,61.6354067 30.89323,62.15624 32.19531,62.15624 C33.2369767,62.15624 34.1484367,61.6354067 34.92969,60.59374 C47.42969,46.14062 53.67969,34.22656 53.67969,24.85156 C53.5494833,18.8619733 51.33594,13.5559833 47.03906,8.93359 C42.7421867,4.31119667 37.7291667,2 32,2 Z M32,16.64843 C34.21354,16.64843 36.10156,17.42968 37.66406,18.99218 C39.22656,20.55468 40.00781,22.4427 40.00781,24.65624 C40.00781,26.8697867 39.22656,28.75781 37.66406,30.32031 C36.10156,31.88281 34.21354,32.66406 32,32.66406 C29.78646,32.66406 27.89844,31.88281 26.33594,30.32031 C24.77344,28.75781 23.99219,26.8697867 23.99219,24.65624 C23.99219,22.4427 24.77344,20.55468 26.33594,18.99218 C27.89844,17.42968 29.78646,16.64843 32,16.64843 Z M32,13.71874 C29.0052067,13.71874 26.4335933,14.79296 24.28516,16.9414 C22.13672,19.08984 21.0625,21.6614533 21.0625,24.65624 C21.0625,27.6510333 22.13672,30.2226467 24.28516,32.37108 C26.4335933,34.51952 29.0052067,35.59374 32,35.59374 C34.9947933,35.59374 37.5664067,34.51952 39.71484,32.37108 C41.86328,30.2226467 42.9375,27.6510333 42.9375,24.65624 C42.9375,21.6614533 41.86328,19.08984 39.71484,16.9414 C37.5664067,14.79296 34.9947933,13.71874 32,13.71874 Z"
        fill="#1A1919"
      />
    </g>
  </svg>
);

export default PinIcon;
